import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Container, Card } from '@material-ui/core';
import Header from './components/header/header.component';
import HomePage from './pages/homepage/homepage.component';
import DesignPage from './pages/design/designpage.component';
import GazebosPage from './pages/gazebo/gazebo.component';
import TreeServicesPage from './pages/tree-services/tree-services.component';
import PatiosPage from './pages/patios/patiospage.component';
import ContactPagve from './pages/contact/contactpage.component';
import AboutPage from './pages/about/about-page.component';
import HomeImprovements from './pages/home-improvements/homeimprovementspage.component';
import IrrigationSystemsPage from './pages/irrigation-systems/irrigation-systems.component';
import CommercialSnowPage from './pages/commercial-snow/commercial-snow-page.component';
import UndergroundDrainageServices from './pages/underground-drainage/underground-drainage.component';
import MaintenacePage from './pages/maintenanace/maintenancepage.component';
import AppFooter from './components/footer/footer.component';
import ScrollToTop from './components/page-content/scroll-to-top.component';
import { Switch, Route } from 'react-router-dom';

function App() {
  return (
    <Container>
      <Card>
        <Header />
        <ScrollToTop />
        <Switch>
          <Route exact path="/" component={HomePage} />
          <Route exact path="/design" component={DesignPage} />
          <Route exact path="/gazebos" component={GazebosPage} />
          <Route exact path="/tree-services" component={TreeServicesPage} />
          <Route exact path="/patios" component={PatiosPage} />
          <Route exact path="/contact" component={ContactPagve} />
          <Route exact path="/home-improvements" component={HomeImprovements} />
          <Route exact path="/about" component={AboutPage} />
          <Route exact path="/maintenance" component={MaintenacePage} />
          <Route exact path="/snow" component={CommercialSnowPage} />
          <Route
            exact
            path="/irrigation-systems"
            component={IrrigationSystemsPage}
          />
          <Route
            exact
            path="/underground-drainage"
            component={UndergroundDrainageServices}
          />
        </Switch>
        <AppFooter />
      </Card>
    </Container>
  );
}
export default App;

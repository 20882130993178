import React from 'react';
import Content from '../../components/page-content/content.component';

const pageTitle = 'Underground Drainage';
const pageDescription =
  'Water control is an issue for many homeowners. From backed up gutters due to large trees to pooling water close to the foundation, these common problems can turn costly if not addressed promptly. We can help solve these issues once and for all.';

const drainageServices = [
  'Gutter Cleaning',
  'Gutter Extensions',
  'Leaf Guards & Filters',
  'Underground Drainage',
  'Grading',
  'Trenching',
];

const videos = [
  { id: 'video1', src: 'https://www.youtube.com/embed/DUkoT6DH9vM' },
];
const UndergroundDrainageServices = () => (
  <Content
    pageTitle={pageTitle}
    description={pageDescription}
    listItemsTitle="Drainage Services Include"
    listItems={drainageServices}
    videoSectionTitle="Project Videos"
    videosArray={videos}
  />
);

export default UndergroundDrainageServices;

import React from 'react';
import Item from './item.component';
import Carousel from 'react-material-ui-carousel';
import designSizedImage from '../../assets/design/design_sized.jpeg';
import patioSizedImage from '../../assets/patios/patio_sized.jpeg';
import gazeboSizedImage from '../../assets/gazebo/gazebo_sized.jpeg';

class ProjectCarousel extends React.Component {
  constructor() {
    super();

    this.state = {
      items: [
        {
          title: 'Gazebos',
          imageUrl: gazeboSizedImage,
          btnText: 'Start your Project',
          linkUrl: 'contact',
        },
        {
          title: 'Outdoor Garden Design',
          imageUrl: designSizedImage,
          btnText: 'Start your Project',
          linkUrl: 'contact',
        },
        {
          title: 'Outdoor Patios',
          imageUrl: patioSizedImage,
          btnText: 'Start your Project',
          linkUrl: 'contact',
        },
      ],
    };
  }

  render() {
    return (
      <Carousel interval="15000" navButtonsAlwaysVisible>
        {this.state.items.map(({ id, ...otherSectionProps }) => (
          <Item key={id} {...otherSectionProps} />
        ))}
      </Carousel>
    );
  }
}

export default ProjectCarousel;

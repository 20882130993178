import React from 'react';
import { Form } from 'react-bootstrap';

const TextAreaFormInput = ({
  controlId,
  label,
  value,
  name,
  handleInputChange,
  hasError,
  feedbackMessage,
}) => (
  <Form.Group controlId={controlId}>
    <Form.Label>{label}</Form.Label>
    <Form.Control
      as="textarea"
      rows={3}
      name={name}
      className={hasError ? 'form-control is-invalid' : 'form-control'}
      value={value}
      onChange={handleInputChange}
    />
    <div className="invalid-feedback">{feedbackMessage}</div>
  </Form.Group>
);

export default TextAreaFormInput;

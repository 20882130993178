import React from 'react';
import Content from '../../components/page-content/content.component';

import GazeboImageOne from '../../assets/gazebo/gazebo_sized.jpeg';
import GazeboImageTwo from '../../assets/gazebo/image0.jpeg';
import GazeboImageThree from '../../assets/gazebo/image1.jpeg';
import GazeboImageFour from '../../assets/gazebo/image2.jpeg';
import GazeboImageFive from '../../assets/gazebo/image3.jpeg';
import GazeboImageSix from '../../assets/gazebo/image4.jpeg';
import GazeboImageSeven from '../../assets/gazebo/image5.jpeg';
import GazeboImageEight from '../../assets/gazebo/image6.jpeg';
import GazeboImageNine from '../../assets/gazebo/image7.jpeg';

const images = [
  { id: 'GazeboImageOne', src: GazeboImageOne },
  { id: 'GazeboImageTwo', src: GazeboImageTwo },
  { id: 'GazeboImageThree', src: GazeboImageThree },
  { id: 'GazeboImageFour', src: GazeboImageFour },
  { id: 'GazeboImageFive', src: GazeboImageFive },
  { id: 'GazeboImageSix', src: GazeboImageSix },
  { id: 'GazeboImageSeven', src: GazeboImageSeven },
  { id: 'GazeboImageEight', src: GazeboImageEight },
  { id: 'GazeboImageNine', src: GazeboImageNine },
];

const videos = [
  { id: 'video1', src: 'https://www.youtube.com/embed/NkBc-xcqxV8' },
];
const pageTitle = 'Gazebos';
const pageDescription =
  'Traditional Amish Gazebos are constructed of wood. Customers love the look, feel and overall richness that wood offers. Wood can be painted or stained and refinished. Garden Gazebos constructed with wood also provide a warm atmosphere for an at home feel. ';
const GazibosPage = () => (
  <Content
    pageTitle={pageTitle}
    description={pageDescription}
    imgs={images}
    videoSectionTitle="Project Videos"
    videosArray={videos}
  />
);

export default GazibosPage;

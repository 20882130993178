import React from 'react';
import { Row, Col } from 'react-bootstrap';

const Featured = () => (
  <div>
    <h2>Featured Content</h2>
    <Row className="justify-content-center" xs="1" sm="1" md="2">
      <Col>
        <br />
        <div className="embed-responsive embed-responsive-16by9">
          <iframe
            title="Embeds Page"
            className="embed-responsive-item"
            src="https://www.youtube.com/embed/6V8rWuNt8-I"
            allowfullscreen
          ></iframe>
        </div>
      </Col>
      <Col>
        <br />
        <div className="embed-responsive embed-responsive-16by9">
          <iframe
            title="Embeds Page"
            className="embed-responsive-item"
            src="https://www.youtube.com/embed/sxUkA-r_UNc"
            allowfullscreen
          ></iframe>
        </div>
      </Col>
    </Row>
    <br />
  </div>
);

export default Featured;

import React from 'react';
import Content from '../../components/page-content/content.component';

import FirePlaceImage from '../../assets/homeimprovement/fireplace.jpeg';
import WoodworkingImage from '../../assets/homeimprovement/woodworking.jpeg';
import ImprovementImageOne from '../../assets/homeimprovement/image0.jpeg';
import ImprovementImageTwo from '../../assets/homeimprovement/image1.jpeg';

const images = [
  { id: 'image1', src: FirePlaceImage },
  { id: 'image2', src: WoodworkingImage },
  { id: 'image3', src: ImprovementImageOne },
  { id: 'image4', src: ImprovementImageTwo },
];

const videos = [
  { id: 'video1', src: 'https://www.youtube.com/embed/6V8rWuNt8-I' },
  { id: 'video2', src: 'https://www.youtube.com/embed/zT5fOmykuAs' },
];
const pageTitle = 'Home Improvements';
const pageDescription =
  'When you work with our home designers, it’s a true team effort, and they’ll treat your home as their own. Because of that commitment, we’re able to produce expertly renovated spaces based on your unique wants and needs. From the initial consultation to choosing materials, we work with you to create a space you’ll be excited to show off.Not only do we complete remodels, but we do room additions throughout the Chicagoland area as well. If you want to expand your kitchen, bedroom, or bathroom, open up your living room to create an open concept, or add more space to your home, our team of room designers and architects can help you reimagine your current floor plan and create a beautiful expansion.';
const homeImprovementServices = [
  'Kitchen Remodeling',
  'Room Additions',
  'Interior Remodeling',
  'Bathroom Remodeling',
  'Flooring',
  'Tuckpointing',
  'Lighting and Sound',
  'Chimney repair/replacement',
];
const WoodworkingPage = () => (
  <Content
    pageTitle={pageTitle}
    description={pageDescription}
    imgs={images}
    listItemsTitle="Home Improvement Services"
    listItems={homeImprovementServices}
    videoSectionTitle="Project Videos"
    videosArray={videos}
  />
);

export default WoodworkingPage;

import React from 'react';
import { withRouter } from 'react-router-dom';
import { Paper, Button } from '@material-ui/core';
import { Row, Col, Image, Figure } from 'react-bootstrap';
import './item.styles.scss';

const Item = ({ title, imageUrl, btnText, history, linkUrl, match }) => (
  <Paper>
    <Col>
      <Row>
        <h2>{title}</h2>
        <Figure>
          <Image src={imageUrl} alt="Logo" fluid />
        </Figure>
      </Row>
      <Row>
        <Button
          className="item-button"
          variant="contained"
          color="primary"
          onClick={() => history.push(`${match.url}${linkUrl}`)}
        >
          {btnText}
        </Button>
      </Row>
    </Col>
  </Paper>
);

export default withRouter(Item);

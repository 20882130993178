import React from 'react';
import Content from '../../components/page-content/content.component';

const pageTitle = 'Irrigation Systems';
const pageDescription =
  'Your designer will set up a personal meeting to assess all of the details necessary for an accurate irrigation system design. Every system we install includes an automatic rain sensor to ensure your system does not waste water when it is raining. We install all of the top brands of sprinkler systems including Rain Bird, Toro, Hunter and Irritrol. Protect your landscape investment and give yourself the leisure time you deserve by allowing us to handle your lawn sprinkler system installation today.';
const IrrigationSystemsPage = () => (
  <Content pageTitle={pageTitle} description={pageDescription} />
);

export default IrrigationSystemsPage;

import React from 'react';
import { Form } from 'react-bootstrap';

const SelectFormInput = ({
  controlId,
  label,
  value,
  name,
  options,
  handleInputChange,
  hasError,
  feedbackMessage,
}) => (
  <Form.Group controlId={controlId}>
    <Form.Label>{label}</Form.Label>
    <Form.Control
      as="select"
      name={name}
      className={hasError ? 'form-control is-invalid' : 'form-control'}
      value={value}
      onChange={handleInputChange}
    >
      {options.map((option) => (
        <option value={option.value}>{option.label}</option>
      ))}
    </Form.Control>
    <div className="invalid-feedback">{feedbackMessage}</div>
  </Form.Group>
);

export default SelectFormInput;

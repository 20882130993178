import React from 'react';
import ServiceButton from './servicebutton.component';
import { Row, Col, Container } from 'react-bootstrap';
import './about.styles.scss';
import DesignImage from '../../assets/design/design_non_sized.jpeg';
import TreeServicesImage from '../../assets/tree-services/tree_services_non_sized.jpeg';
import PatioImage from '../../assets/patio.jpeg';
import Fireplace from '../../assets/homeimprovement/fireplace_non_sized.jpeg';
import SprinklerSystemImage from '../../assets/sprinkler-system.jpg';
import FlooringImage from '../../assets/gazebo/gazebo.jpeg';

const About = () => (
  <div>
    <h2>Castro's Landscaping Inc.</h2>
    <Container>
      <p>
        Castros Landscaping Incorporated serves the Chicagoland area and
        southwest suburbs. Castros Landscaping originally started as a
        landscaping company by my father Miguel Castro Sr in 1980. We expanded
        our services from just landscaping to home improvements, gazebos, decks,
        and tree services. Our goal is to bring your dream project to life.
        There is no job too small or too big for us because we work with the
        best crew to ensure that our quality exceeds your expectations.{' '}
        <a href="/contact" Contact>
          Contact us{' '}
        </a>
        for a free quote.
      </p>
    </Container>
    <Row xs="1" sm="2" md="4" className="justify-content-center">
      <Col className="col-md-4">
        <h2>Landscape Design</h2>
        <ServiceButton
          btnText="View"
          linkUrl="/design"
          imageUrl={DesignImage}
        />
        <br />
        <h2>Patios</h2>
        <ServiceButton btnText="View" linkUrl="/patios" imageUrl={PatioImage} />
      </Col>
      <Col className="col-md-4">
        <h2>Gazebos</h2>
        <ServiceButton
          btnText="View"
          linkUrl="/gazebos"
          imageUrl={FlooringImage}
        />
        <br />
        <h2>Home Improvements</h2>
        <ServiceButton
          btnText="View"
          linkUrl="/home-improvements"
          imageUrl={Fireplace}
        />
      </Col>
      <Col className="col-md-4">
        <h2>Tree Services</h2>
        <ServiceButton
          btnText="View"
          linkUrl="/tree-services"
          imageUrl={TreeServicesImage}
        />
        <br />
        <h2>Irrigation Systems</h2>
        <ServiceButton
          btnText="View"
          linkUrl="/irrigation-systems"
          imageUrl={SprinklerSystemImage}
        />
      </Col>
    </Row>
  </div>
);

export default About;

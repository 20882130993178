import React from 'react';
import { Paper, Button } from '@material-ui/core';
import { Row, Col, Image, Figure, Container } from 'react-bootstrap';
import Carousel from 'react-material-ui-carousel';
import { withRouter } from 'react-router-dom';

const content = ({
  pageTitle,
  description,
  imgs,
  videosArray,
  videoSectionTitle,
  history,
  listItemsTitle,
  listItems,
}) => (
  <Paper>
    <Container>
      <h2>{pageTitle}</h2>
      <p>{description}</p>
      <ImagesDisplay imgs={imgs} />
      <Button
        className="item-button"
        variant="contained"
        color="primary"
        onClick={() => history.push('/contact')}
      >
        Start your project
      </Button>
      {!listItemsTitle ? null : <h2>{listItemsTitle}</h2>}
      <ListItems listItems={listItems} />
      {!videoSectionTitle ? null : <h2>{videoSectionTitle}</h2>}
      <VideosDisplay videosArray={videosArray} />
    </Container>
  </Paper>
);

function ImagesDisplay({ imgs }) {
  if (!imgs) {
    return null;
  }

  return (
    <Row>
      <Container>
        <Figure>
          <Carousel interval="15000" navButtonsAlwaysVisible>
            {imgs.map((img) => (
              <Image id={img.id} src={img.src} fluid />
            ))}
          </Carousel>
        </Figure>
      </Container>
    </Row>
  );
}

function VideosDisplay({ videosArray }) {
  if (!videosArray) {
    return null;
  }

  return (
    <Row className="justify-content-center" xs="1" sm="1" md="2">
      {videosArray.map((video) => (
        <Col>
          <div
            className={
              videosArray.length > 1
                ? 'embed-responsive embed-responsive-16by9'
                : 'embed-responsive embed-responsive-1by1'
            }
          >
            <iframe
              id={video.id}
              title="Embeds Page"
              className="embed-responsive-item"
              src={video.src}
              allowfullscreen
            ></iframe>
          </div>
          <br />
        </Col>
      ))}
    </Row>
  );
}

function ListItems({ listItems }) {
  if (!listItems) {
    return null;
  }
  return (
    <Row className="justify-content-center" xs="1" sm="2" md="4">
      {listItems.map((item) => (
        <Col>
          <ul>
            <li>{item}</li>
          </ul>
        </Col>
      ))}
    </Row>
  );
}

export default withRouter(content);

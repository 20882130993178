import React from 'react';
import Content from '../../components/page-content/content.component';

const pageTitle = "Castro's Landscaping Inc.";
const pageDescription =
  'Castros Landscaping Incorporated serves the Chicagoland area and southwest suburbs. Castros Landscaping originally started as alandscaping company by my father Miguel Castro Sr in 1980. We expanded our services from just landscaping to home improvements, gazebos, decks, and tree services. Our goal is to bring your dream project to life. There is no job too small or too big for us because we work with the best crew to ensure that our quality exceeds your expectations. Click the start your project button below to get in contact with us for your free quote!';

const AboutPage = () => (
  <Content pageTitle={pageTitle} description={pageDescription} />
);

export default AboutPage;

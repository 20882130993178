import React from 'react';
import logo from '../../assets/logo.jpg';
import { LocalPhone } from '@material-ui/icons';
import { Navbar, Nav, NavDropdown } from 'react-bootstrap';
// import { ReactComponent as Logo } from '../../assets/logo.jpg';

import './header.styles.scss';
const Header = () => (
  <Navbar collapseOnSelect expand="lg" bg="light" variant="light">
    <Navbar.Brand href="/">
      <img
        src={logo}
        width="100"
        height="50"
        className="d-inline-block align-top"
        alt="React Bootstrap logo"
      />
    </Navbar.Brand>
    <Navbar.Toggle aria-controls="responsive-navbar-nav" />
    <Navbar.Collapse id="responsive-navbar-nav">
      <Nav className="mr-auto">
        <NavDropdown
          className="collasible-nav-dropdown"
          title="Services"
          id="services-dropdown"
        >
          <NavDropdown.Item href="/design">Landscape Design</NavDropdown.Item>
          <NavDropdown.Item href="/gazebos">Gazebos</NavDropdown.Item>
          <NavDropdown.Item href="/tree-services">
            Tree Services
          </NavDropdown.Item>
          <NavDropdown.Item href="/patios">Patios</NavDropdown.Item>
          <NavDropdown.Item href="/home-improvements">
            Home Improvements
          </NavDropdown.Item>
          <NavDropdown.Item href="/irrigation-systems">
            Irrigation Systems
          </NavDropdown.Item>
          <NavDropdown.Item href="/maintenance">Maintenance</NavDropdown.Item>
          <NavDropdown.Item href="/snow">Commercial Snow</NavDropdown.Item>
          <NavDropdown.Item href="/underground-drainage">
            Underground Drainage Services
          </NavDropdown.Item>
        </NavDropdown>
        <Nav.Link className="option" href="/contact">
          Contact
        </Nav.Link>
        <Nav.Link className="option" href="/about">
          About
        </Nav.Link>
      </Nav>
      <Nav.Item>
        <a href="tel:630-286-3008">
          <LocalPhone />
          (630) 286-3008
        </a>
      </Nav.Item>
    </Navbar.Collapse>
  </Navbar>
);

export default Header;

import React from 'react';
import Content from '../../components/page-content/content.component';

import DesignImage from '../../assets/design/design_sized.jpeg';
import DesignImageOne from '../../assets/design/image0.jpeg';
import DesignImageTwo from '../../assets/design/image1.jpeg';
import DesignImageThree from '../../assets/design/image2.jpeg';

const images = [
  { id: 'image1', src: DesignImage },
  { id: 'image2', src: DesignImageOne },
  { id: 'image3', src: DesignImageTwo },
  { id: 'image3', src: DesignImageThree },
];

const videos = [
  { id: 'video1', src: 'https://www.youtube.com/embed/wwfUP8StKL4' },
];

const designServices = [
  'Clear Area for Construction',
  'Lay Foundations',
  'Start with Hardscape Elements',
  'Walls and other Structural Elements',
];
const pageTitle = 'Landscape Design';
const pageDescription =
  'We will review your site again to take accurate measurements and to mark off the area for construction. Starting a project correctly makes a big difference. This preparation step is critical to a successful landscape project. From site staging to drainage and grading planning, this step is extremely important.This is when we will begin to lay the foundation or skeleton of the project. It’s hardest at this stage with everything going on to visualize what will come from the construction because we are putting in place the needed foundational elements first.';
const DesignPage = () => (
  <Content
    pageTitle={pageTitle}
    description={pageDescription}
    imgs={images}
    videoSectionTitle="Project Videos"
    listItemsTitle="Design Services"
    listItems={designServices}
    videosArray={videos}
  />
);

export default DesignPage;

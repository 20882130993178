import React from 'react';
import { Container, Jumbotron } from 'react-bootstrap';

const Communities = () => (
  <Jumbotron fluid id="communities-jumbotron">
    <Container>
      <h2>Communities We Serve</h2>
      <p>
        We proudly serve the Chicagoland and southwest suburbs of Burr Ridge,
        Downers Grove, Hinsdale, Darien, and Naperville.
      </p>
    </Container>
  </Jumbotron>
);

export default Communities;

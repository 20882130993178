import React from 'react';
import { Form } from 'react-bootstrap';

const TextFormInput = ({
  controlId,
  label,
  value,
  name,
  placeholder,
  handleInputChange,
  hasError,
  feedbackMessage,
  isEmail,
}) => (
  <Form.Group controlId={controlId}>
    <Form.Label>{label}</Form.Label>
    <Form.Control
      type={!isEmail ? 'text' : 'email'}
      className={hasError ? 'form-control is-invalid' : 'form-control'}
      placeholder={placeholder}
      name={name}
      value={value}
      onChange={handleInputChange}
    />
    <div className="invalid-feedback">{feedbackMessage}</div>
    {isEmail ? (
      <Form.Text className="text-muted">
        We'll never share your email with anyone else.
      </Form.Text>
    ) : null}
  </Form.Group>
);

export default TextFormInput;

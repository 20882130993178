import React from 'react';
import Content from '../../components/page-content/content.component';

const pageTitle = 'Commercial Snow';
const pageDescription =
  'With our commercial equipment and total dedication to customer satisfaction, our team of snow management experts are here to clear your lots professionally and quickly with every snowfall. Our fleet of commercial quality snowplows, pushers and removal equipment allows us to handle any and all snow clearing jobs. We also offer snow removal plans for blizzards or other large, long-lasting storms that drop 9 inches or more of snow at one time. With our equipment and trained crews, we clear and  make all sidewalks safe. Castros Landscaping is committed to ensuring our customer’s walkways and parking lots remain completely safe after we have finished plowing. Our salt is treated so it will act faster and work better, even at colder temperatures or in heavy storms.';
const CommercialSnowPage = () => (
  <Content pageTitle={pageTitle} description={pageDescription} />
);

export default CommercialSnowPage;

import React from 'react';
import { Row, Col, Container, Nav } from 'react-bootstrap';
import { Paper } from '@material-ui/core';
import './footer.styles.scss';
import {
  LocationOn,
  LocalPhone,
  Email,
  Instagram,
  Pinterest,
  Twitter,
  YouTube,
} from '@material-ui/icons';

const Footer = () => {
  return (
    <Paper id="footer-container">
      <div>
        <Container>
          <Row className="justify-content-center" xs="1" sm="1" md="3">
            <Col className="col-md-4 nav-items">
              <a className="option" href="/about">
                About
              </a>
              <br />
              <a className="option" href="/contact">
                Contact
              </a>
            </Col>
            <Col className="col-md-4 contact-info">
              <a href="https://goo.gl/maps/Y1BUwPCCCK1qXrWj7" target="_blank">
                <LocationOn />
                3500 W 74th St. Chicago, IL 60629
              </a>
              <br />
              <a href="tel:630-286-3008">
                <LocalPhone />
                (630) 286-3008
              </a>
              <br />
              <a href="mailto:castros.landscaping.est1990@gmail.com">
                <Email />
                castros.landscaping.est1990@gmail.com
              </a>
            </Col>
            <Col className="col-md-4 social">
              <a href="https://twitter.com/castrosinc" target="_blank">
                <Twitter style={{ color: 'lightblue' }} />
              </a>
              <a href="https://www.pinterest.com/CastroL1980/" target="_blank">
                <Pinterest style={{ color: 'Red' }} />
              </a>
              <a
                href="https://www.youtube.com/channel/UCmOxr0rKEashflB1qrMH9RQ"
                target="_blank"
              >
                <YouTube style={{ color: 'DarkRed' }} />
              </a>
              <a
                href="https://www.instagram.com/castros_incorp/"
                target="_blank"
              >
                <Instagram style={{ color: '#8a3ab9' }} />
              </a>
              <br />
              &copy; {new Date().getFullYear()}
              Copyright Castros Landscaping Inc.
            </Col>
          </Row>
        </Container>
      </div>
    </Paper>
  );
};

export default Footer;

import React, { useState } from 'react';
import { Button } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { Col, Image } from 'react-bootstrap';
import './servicebutton.styles.scss';

export default function ServiceButton({ btnText, linkUrl, imageUrl }) {
  const [hovered, setHovered] = useState(false);
  const toggleHover = () => setHovered(!hovered);
  let history = useHistory();

  return (
    <Col
      className="image-container"
      onMouseEnter={toggleHover}
      onMouseLeave={toggleHover}
      onClick={() => history.push(linkUrl)}
    >
      <Image
        style={{ position: 'relative' }}
        className="circle-img img-circle shadow-lg p-2 mb-5"
        roundedCircle
        src={imageUrl}
      />
      <Button
        variant="contained"
        color="primary"
        className={hovered ? 'show-btn' : 'hide-btn'}
        onClick={() => history.push(linkUrl)}
      >
        {btnText}
      </Button>
    </Col>
  );
}

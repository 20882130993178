import React from 'react';
import Content from '../../components/page-content/content.component';

import TreeServicesImageOne from '../../assets/tree-services/tree-services.jpg';
import TreeServicesImageTwo from '../../assets/tree-services/image0.jpeg';
import TreeServicesImageThree from '../../assets/tree-services/image1.jpeg';

const images = [
  { id: 'image1', src: TreeServicesImageOne },
  { id: 'image2', src: TreeServicesImageTwo },
  { id: 'image3', src: TreeServicesImageThree },
];

const videos = [
  { id: 'video1', src: 'https://www.youtube.com/embed/3WCaK64kR_s' },
];
const pageTitle = 'Tree Services';
const pageDescription =
  'Every tree is either an asset or a liability. When a professional tree risk assessment uncovers a potential issue – perhaps the tree is damaged or diseased beyond repair – tree removal or tree cutting may be required.The cost of tree removal really depends on the tree and to give an accurate tree removal cost, an arborist needs to see it in person.';
const TreeServices = () => (
  <Content
    pageTitle={pageTitle}
    description={pageDescription}
    imgs={images}
    videoSectionTitle="Project Videos"
    videosArray={videos}
  />
);

export default TreeServices;

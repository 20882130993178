import React from 'react';
import Content from '../../components/page-content/content.component';

import PatiosImageOne from '../../assets/patios/patio_sized.jpeg';
import PatiosImageTwo from '../../assets/patios/image0.jpeg';
import PatiosImageThree from '../../assets/patios/image1.jpeg';
import PatiosImageFour from '../../assets/patios/image3.jpeg';
import PatiosImageFive from '../../assets/patios/image4.jpeg';
import PatiosImageSix from '../../assets/patios/image5.jpeg';

const images = [
  { id: 'image1', src: PatiosImageOne },
  { id: 'image2', src: PatiosImageTwo },
  { id: 'image3', src: PatiosImageThree },
  { id: 'image4', src: PatiosImageFour },
  { id: 'image5', src: PatiosImageFive },
  { id: 'image6', src: PatiosImageSix },
];
const videos = [
  { id: 'video1', src: 'https://www.youtube.com/embed/01e4Y2O-teg' },
];
const pageTitle = 'Patios Design';
const pageDescription =
  'Your custom designed unilock paver patio will be installed by experienced hardscape installers using unilock’s u-level tool.  We will treat your property as if it is our own – working carefully and paying attention to the details needed to create a perfect project to enhance the value and beauty of your home.';
const pageDescriptionContinuted =
  ' We take pride in each custom designed paver project that we design and complete.  We are committed to providing only the best quality workmanship in the Chicagoland area.';
const PatiosPage = () => (
  <Content
    pageTitle={pageTitle}
    description={pageDescription + pageDescriptionContinuted}
    imgs={images}
    videoSectionTitle="Project Videos"
    videosArray={videos}
  />
);

export default PatiosPage;

import React, { useState } from 'react';
import { Button } from '@material-ui/core';
import { Container, Form, Modal, Spinner } from 'react-bootstrap';
import axios from 'axios';
import './contactpage.styles.scss';
import TextFormInput from '../../components/form/text-form-input.component';
import TextAreaFormInput from '../../components/form/textarea-form-input.component';
import SelectFormInput from '../../components/form/select-form-input.component';

export default function Contact(props) {
  const stateOptions = [
    { label: '(Please Select One)', value: '' },
    { label: 'Illinois', value: 'IL' },
    { label: 'Indiana', value: 'IN' },
    { label: 'Wisconsin', value: 'WI' },
    { label: 'Iowa', value: 'IA' },
  ];

  const interestedInOptions = [
    { label: '(Please Select One)', value: '' },
    { label: 'Landscape Design', value: 'Landscape Design' },
    { label: 'Home Improvement', value: 'Home Improvement' },
    { label: 'Outdoor Living', value: 'Outdoor Living' },
    { label: 'Tree Services', value: 'Tree Services' },
    { label: 'Irrigation Systems', value: 'Irrigation Systems' },
    { label: 'Maintenance', value: 'Maintenance' },
    { label: 'Commercial Snow', value: 'Commercial Snow' },
  ];

  const hearAboutUsOptions = [
    { label: '(Please Select One)', value: '' },
    { label: 'Friend', value: 'friend' },
    { label: 'Yelp!', value: 'yelp' },
    { label: 'Facebook', value: 'facebook' },
    { label: 'Instagram', value: 'instagram' },
    { label: 'Google', value: 'google' },
    { label: 'Yahoo', value: 'yahoo' },
    { label: 'Bing', value: 'bing' },
    { label: 'Other', value: 'other' },
  ];
  const [values, setValues] = useState({
    firstName: '',
    lastName: '',
    companyName: '',
    email: '',
    phone: '',
    address: '',
    city: '',
    state: '',
    zip: '',
    interestedIn: '',
    hearAboutUs: '',
    comments: '',
    errors: [],
  });

  const [modalShow, setModalShow] = React.useState(false);
  const [spinnerShow, setSpinnerShow] = React.useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(values);
    if (validateForm(values).length === 0) {
      setSpinnerShow(true);
      axios
        .post(
          `https://us-central1-castroslandscaping.cloudfunctions.net/contact`,
          values
        )
        .then((res) => {
          if (res.data.success) {
            console.log('Form submitted succesfully');
            setModalShow(true);
          } else {
            console.log('Call to post form failed with errors');
            // Forcing re-render to show the errors
            setValues({ ...values, errors: res.data.errors });
          }
          setSpinnerShow(false);
        });
    } else {
      console.log('Form submitted with validtion errors');
    }
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setValues({ ...values, [name]: value });
  };

  function hasError(key) {
    return values.errors.indexOf(key) !== -1;
  }

  function validateForm(values) {
    values.errors = [];
    if (!values.firstName) {
      values.errors.push('firstName');
    }

    if (!values.lastName) {
      values.errors.push('lastName');
    }

    if (!values.email) {
      values.errors.push('email');
    }

    if (!values.phone) {
      values.errors.push('phone');
    } else {
      let cleanPhoneNumber = values.phone.replace(/\D/g, '');
      let isValid =
        cleanPhoneNumber.length === 10 || cleanPhoneNumber.length === 11;
      if (!isValid) {
        values.errors.push('phone');
      }
    }

    if (!values.address) {
      values.errors.push('address');
    }

    if (!values.city) {
      values.errors.push('city');
    }

    if (!values.state || values.state === '(Please Select One)') {
      values.errors.push('state');
    }

    if (!values.zip) {
      values.errors.push('zip');
    } else {
      let cleanZipCode = values.zip.replace(/\D/g, '');
      let isValid = cleanZipCode.length === 5 || cleanZipCode.length === 9;
      if (!isValid) {
        values.errors.push('zip');
      }
    }

    if (!values.interestedIn || values.interestedIn === '(Please Select One)') {
      values.errors.push('interestedIn');
    }

    if (!values.hearAboutUs || values.hearAboutUs === '(Please Select One)') {
      values.errors.push('hearAboutUs');
    }
    // Forcing re-render to show the errors
    setValues({ ...values, errors: values.errors });
    return values.errors;
  }

  function ConfirmationModal(props) {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <h4>Thank you!</h4>
          <p>
            Thanks for reaching out we will get in touch with you as soon as
            possible and work to get you a quote on your project.
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button color="primary" href="/">
            Home
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }

  return (
    <div>
      <Container>
        <h2>Contact Us Now</h2>
        <Form onSubmit={handleSubmit}>
          <TextFormInput
            controlId="formGroupFirstName"
            label="First Name*"
            value={values.firstName}
            name="firstName"
            placeholder="Enter First Name"
            handleInputChange={handleInputChange}
            hasError={hasError('firstName')}
            feedbackMessage="Please enter your first name."
          />
          <TextFormInput
            controlId="formGroupLastName"
            label="Last Name*"
            value={values.lastName}
            name="lastName"
            placeholder="Enter Last Name"
            handleInputChange={handleInputChange}
            hasError={hasError('lastName')}
            feedbackMessage="Please enter your last name."
          />
          <TextFormInput
            controlId="formGroupCompanyName"
            label="Company Name"
            value={values.companyName}
            name="companyName"
            placeholder="Enter Company Name"
            handleInputChange={handleInputChange}
            hasError={hasError('companyName')}
            feedbackMessage="Please enter a valid company name."
          />
          <TextFormInput
            controlId="formBasicEmail"
            label="Email address*"
            value={values.email}
            name="email"
            placeholder="Enter email"
            handleInputChange={handleInputChange}
            hasError={hasError('email')}
            feedbackMessage="Please enter a valid email."
            isEmail="true"
          />
          <TextFormInput
            controlId="formGroupPhone"
            label="Phone Number"
            value={values.phone}
            name="phone"
            placeholder="Enter Phone Number"
            handleInputChange={handleInputChange}
            hasError={hasError('phone')}
            feedbackMessage="Please enter a valid phone number."
          />
          <TextFormInput
            controlId="formGroupAddress"
            label="Address*"
            value={values.address}
            name="address"
            placeholder="Enter Address"
            handleInputChange={handleInputChange}
            hasError={hasError('address')}
            feedbackMessage="Please enter a valid address."
          />
          <TextFormInput
            controlId="formGroupCity"
            label="City*"
            value={values.city}
            name="city"
            placeholder="Enter City"
            handleInputChange={handleInputChange}
            hasError={hasError('city')}
            feedbackMessage="Please enter a valid city."
          />
          <SelectFormInput
            options={stateOptions}
            controlId="formGroupState"
            label="State*"
            handleInputChange={handleInputChange}
            hasError={hasError('state')}
            value={values.state}
            name="state"
            feedbackMessage="Please select a valid state"
          />
          <TextFormInput
            controlId="formGroupZipCode"
            label="Zip Code*"
            value={values.zip}
            name="zip"
            placeholder="Enter Zip Code"
            handleInputChange={handleInputChange}
            hasError={hasError('zip')}
            feedbackMessage="Please enter a valid zip code."
          />
          <SelectFormInput
            options={interestedInOptions}
            controlId="formGroupInterestedIn"
            label="Interested In*"
            handleInputChange={handleInputChange}
            hasError={hasError('interestedIn')}
            value={values.interestedIn}
            name="interestedIn"
            feedbackMessage="Please select what service you are interested in."
          />
          <SelectFormInput
            options={hearAboutUsOptions}
            controlId="formGroupHearAboutUs"
            label="Hear About Us*"
            handleInputChange={handleInputChange}
            hasError={hasError('hearAboutUs')}
            value={values.hearAboutUs}
            name="hearAboutUs"
            feedbackMessage="Please let us know how you heard about us."
          />
          <TextAreaFormInput
            controlId="formGroupComments"
            label="Comments or Information about your project"
            value={values.comments}
            name="comments"
            handleInputChange={handleInputChange}
            hasError={hasError('comments')}
            feedbackMessage="The comment you entered is too long or contains invalid
            characters."
          />
          <Button
            variant="contained"
            className="item-button"
            color="primary"
            type="submit"
          >
            {!spinnerShow ? (
              'Submit'
            ) : (
              <Spinner
                as="span"
                animation="grow"
                size="sm"
                role="status"
                aria-hidden="true"
              />
            )}
          </Button>
        </Form>
        <br />
        <ConfirmationModal
          show={modalShow}
          onHide={() => setModalShow(false)}
        />
      </Container>
    </div>
  );
}

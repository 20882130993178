import React from 'react';
import ProjectCarousel from '../../components/carousel/project-carousel.component';
import About from '../../components/about/about.component';
import Communities from '../../components/communities/communities.component';
import Featured from '../../components/featured/featured.component';
import { Card } from '@material-ui/core';
import './homepage.styles.scss';

const HomePage = () => (
  <Card>
    <ProjectCarousel />
    <About />
    <Communities />
    <Featured />
  </Card>
);

export default HomePage;

import React from 'react';
import Content from '../../components/page-content/content.component';

const pageTitle = 'Maintenance';
const pageDescription =
  'Here at Castros Landscaping Inc. we believe that properly maintaining your new landscape investment is critical to its beauty and longevity. Our team is dedicated to providing the best full-service lawn care and landscape maintenance in the Chicago area. We have a variety of different lawn maintenance and care packages available, from monthly residential and commercial programs to spring and fall property cleanups. We also provide snow and ice management services to all our customers helping take care of your property year-round. If you\'re interested in exploring one of our maintenacne packages or would just like to find out a little more about what services we offer click the "Start your Project" button below.';
const MaintenancePage = () => (
  <Content pageTitle={pageTitle} description={pageDescription} />
);

export default MaintenancePage;
